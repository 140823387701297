// Animation styles_1

.animation__style__1 {
  animation: animation__key__1 6s linear 0s infinite reverse both;
}

@-webkit-keyframes animation__key__1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }

  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }

  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }

  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes animation__key__1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }

  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }

  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }

  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }

  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
// Animation styles_2

.animation__style__2 {
  animation: animation__key__2 120s linear 0s infinite alternate-reverse both;
}

@-webkit-keyframes animation__key__2 {
  0% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
  }

  100% {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
  }
}

@keyframes animation__key__2 {
  0% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
  }

  100% {
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
  }
}
// Animation styles_3

.animation__style__3 {
  animation: animation__key__3 60s linear 0s infinite alternate-reverse both;
}

@-webkit-keyframes animation__key__3 {
  0% {
    -webkit-transform: translateX(300px) scale(1);
    transform: translateX(300px) scale(1);
  }

  100% {
    -webkit-transform: translateX(-300px) scale(1.1);
    transform: translateX(-300px) scale(1.1);
  }
}

@keyframes animation__key__3 {
  0% {
    -webkit-transform: translateX(300px) scale(1);
    transform: translateX(300px) scale(1);
  }

  100% {
    -webkit-transform: translateX(-300px) scale(1.1);
    transform: translateX(-300px) scale(1.1);
  }
}
