.introSection {
  background: url("~/public/Assets/Images/bg_img/introImg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 120px 0;
  @media #{$lg-device} {
    padding: 100px 0;
  }
  @media #{$md-device} {
    padding: 80px 0;
  }
  @media #{$sm-device} {
    padding: 60px 0;
  }

  &::before {
    background-color: #000;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    opacity: 0.7;
  }
}
