.courseAdvisor {
  .itemContainer {
    .img__file {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: rgb(51, 51, 51);
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(51, 51, 51, 1)),
          color-stop(41%, rgba(51, 51, 51, 0.7287289915966386)),
          color-stop(76%, rgba(51, 51, 51, 0.41780462184873945)),
          to(rgba(241, 97, 38, 0))
        );
        background: -o-linear-gradient(
          top,
          rgba(51, 51, 51, 1) 0%,
          rgba(51, 51, 51, 0.7287289915966386) 41%,
          rgba(51, 51, 51, 0.41780462184873945) 76%,
          rgba(241, 97, 38, 0) 100%
        );
        background: linear-gradient(
          180deg,
          rgba(51, 51, 51, 1) 0%,
          rgba(51, 51, 51, 0.7287289915966386) 41%,
          rgba(51, 51, 51, 0.41780462184873945) 76%,
          rgba(241, 97, 38, 0) 100%
        );
        opacity: 0;
        visibility: hidden;
        width: 100%;
        height: 100%;
        z-index: 1;
        -webkit-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear;
      }
      .icons {
        z-index: 99;
        right: 18px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        overflow: hidden;
        .icon__body {
          span {
            -webkit-transform: translateX(40px);
            -ms-transform: translateX(40px);
            transform: translateX(40px);
            opacity: 0;
            visibility: hidden;
            padding: 5px;
            display: block;
            cursor: pointer;
            font-size: 25px;
            &:nth-child(1) {
              -webkit-transition: all 0.3s linear;
              -o-transition: all 0.3s linear;
              transition: all 0.3s linear;
            }
            &:nth-child(2) {
              -webkit-transition: all 0.4s linear;
              -o-transition: all 0.4s linear;
              transition: all 0.4s linear;
            }
            &:nth-child(3) {
              -webkit-transition: all 0.5s linear;
              -o-transition: all 0.5s linear;
              transition: all 0.5s linear;
            }
            &:nth-child(4) {
              -webkit-transition: all 0.6s linear;
              -o-transition: all 0.6s linear;
              transition: all 0.6s linear;
            }
            svg {
              color: #fff;
              -webkit-transition: all 0.2s linear;
              -o-transition: all 0.2s linear;
              transition: all 0.2s linear;
            }
            &:hover {
              svg {
                color: #f16126;
              }
            }
          }
        }
      }
    }

    &:hover {
      .img__file {
        &::before {
          visibility: visible;
          opacity: 0.7;
        }
        .icons {
          .icon__body {
            span {
              -webkit-transform: translateX(0);
              -ms-transform: translateX(0);
              transform: translateX(0);
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
