@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&family=Poppins:wght@400;500;600;700;800;900&family=Roboto:wght@400;500;700;900&display=swap");

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  overflow-x: hidden !important;
}

body {
  font-family: "Poppins", sans-serif;
  color: #77838f;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  color: #333;
}
img {
  height: auto;
  max-width: 100%;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

:focus-visible {
  outline: none;
}

.text-primary {
  color: #f16126;
}
.bg-theme {
  background-color: var(--theme_color);
}
