.loader {
  background: #f1f1f1;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  top: 0;

  &.show {
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    visibility: visible;
    opacity: 1;
  }
  &.hide {
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    visibility: hidden;
    opacity: 0;
  }
}

.loaderBody,
.loaderBody * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loaderBody {
  height: 60px;
  width: 60px;
  overflow: hidden;
}

.loaderBody .loaderBody-inner {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}

.loaderBody .spinner-circle {
  display: block;
  position: absolute;
  color: #f16126;
  font-size: calc(60px * 0.24);
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loaderBody .loader-line {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  border-left-width: calc(60px / 25);
  border-top-width: calc(60px / 25);
  border-left-color: #f16126;
  border-left-style: solid;
  border-top-style: solid;
  border-top-color: transparent;
}

.loaderBody .loader-line:nth-child(1) {
  -webkit-animation: loaderBody-animation-1 1s linear infinite;
  animation: loaderBody-animation-1 1s linear infinite;
  -webkit-transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
  transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
}

.loaderBody .loader-line:nth-child(2) {
  -webkit-animation: loaderBody-animation-2 1s linear infinite;
  animation: loaderBody-animation-2 1s linear infinite;
  -webkit-transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
  transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
}

.loaderBody .loader-line:nth-child(3) {
  -webkit-animation: loaderBody-animation-3 1s linear infinite;
  animation: loaderBody-animation-3 1s linear infinite;
  -webkit-transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
  transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
}

@-webkit-keyframes loaderBody-animation-1 {
  100% {
    -webkit-transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes loaderBody-animation-1 {
  100% {
    -webkit-transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
  }
}

@-webkit-keyframes loaderBody-animation-2 {
  100% {
    -webkit-transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes loaderBody-animation-2 {
  100% {
    -webkit-transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
  }
}

@-webkit-keyframes loaderBody-animation-3 {
  100% {
    -webkit-transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
  }
}

@keyframes loaderBody-animation-3 {
  100% {
    -webkit-transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
  }
}
