@tailwind base;
@tailwind components;
@tailwind utilities;

/* SCSS Style */

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "/public/Assets/Scss/style.scss";
@import "node_modules/react-modal-video/scss/modal-video.scss";
